/* 画面共通スタイル */
.titleStyle {
  margin-top: 24px;
  margin-bottom: 24px;
}

.latter-half-omitted-1 {
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* ブラウザがサポートしていない場合のフェールセーフ */
  max-height: 24px;
}

/* 共通ボタン */
.common-button {
  color: inherit;
  padding: 5px 15px;
  border-radius: 15px;
  border-color: inherit;
  background-color: inherit;
}

.common-button:hover {
  border-color: inherit;
}

/* 保有MCA詳細画面 権利エリア */
.rights-table {
  overflow-y: hidden;
}

.rights-header {
  border: none !important;
  font-size: 1.5em;
  padding-bottom: 1px;
  white-space: nowrap;
}

.rights-body {
  border: none !important;
  padding-bottom: 1px;
  white-space: nowrap;
}

.history-header {
  border: none !important;
  font-weight: bold;
  font-size: 1.5em;
  padding-bottom: auto;
}

.history-body {
  border: none !important;
  padding-bottom: 0px !important;
  white-space: nowrap;
  padding-top: 0px !important;
}

/* アカウント情報表示画面 */
.account-typography {
  white-space: nowrap !important;
}

/* アカウント情報編集画面 */
.account-input {
  flex-grow: 1
}

.row-pair {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.assist-message {
  opacity: 0.7 !important;
}

.detailInformationTitleStyle {
  color: #a9a9a9;
  margin-top: 16px;
}

/* 画像スクロールエリア */
.slick-slider {
  display: flex !important;
  margin-left: 0 !important;
  width: 100% !important;
}

.slick-slide {
  display: flex !important;
  justify-content: center !important;
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.slick-arrow {
  z-index: 2 !important;
  margin: 0px 5px;
  width: 45px !important;
  height: 45px !important;
  top: 40% !important;
  transform: none !important;
  background-color: rgba(250, 250, 250, 0.6) !important;
}

.slick-arrow:hover {
  background-color: rgba(250, 250, 250, 0.8) !important;
}

.slick-next {
  right: 0 !important;
  color: #000000 !important;
}

.slick-prev {
  left: 0 !important;
  color: #000000 !important;
}

.slick-next::before,
.slick-prev::before {
  font-size: 1.5rem !important;
  content: none !important;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: .2 !important;
}

.slick-list {
  flex-grow: 1 !important;
}

.slick-track {
  width: max-content !important;
  margin: 0 0 !important;
}

.slick-slide>div {
  width: 100%;
}

.Error-Zone fieldset {
  border-color: #f44336 !important;
}

.spanError-Zone {
  color: #f44336 !important;
}
